import {makeAutoObservable} from "mobx";
import AppealService from "../services/AppealService";
import { socket } from "../socket";

class AppealsStore {
    
    appeal = {};
    appeals = [];
    isLoading = false;

    paginationModel = {
        pageSize: 20,
        page: 0,
    };
    filterModel = {
        provider: 0,
        group: 0,
        open: 1,
        close: 0,
        quickFilterValues: '',
    };

    constructor() {
        makeAutoObservable(this)
        this.fetchAppeals()
        socket.on("appeal_update", (data) => {
            this.fetchAppeals()
            console.log(data.appeal_id)
            if (data.appeal_id === this.appeal.id) {
                this.fetchAppeal(data.appeal_id)
                // dispatch(FetchDirectConversations({ conversations: data }));
            }
        });
        
        this.isLoading = true
    }

    setFilterModel(filterModel) {
        this.filterModel = filterModel;
        this.fetchAppeals()
    }

    setPaginationModel(paginationModel) {
        this.paginationModel = paginationModel;
        this.fetchAppeals()
    }

    async fetchAppeal( appealId ) {
        try {
            const appeal = await AppealService.getAppeal(appealId);
            this.appeal = appeal.data;
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

    async fetchAppeals(  ) {
        try {
            const appeals = await AppealService.getAppeals(this.paginationModel.page, this.paginationModel.pageSize, this.filterModel);
            this.appeals = appeals.data.appeals;
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

}

export default new AppealsStore()