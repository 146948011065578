import {
    Box,
    Stack
} from "@mui/material";
import Header from "../../components/Header";
import Appeals from "../../sections/Appeals/Appeals";
import Appeal from "../../sections/Appeal/Appeal";
import GlobalLiveSearch from "../../sections/GlobalLiveSearch/GlobalLiveSearch";


const AppAppeals = () => {

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Appeals" subtitle="Appeals" />
            </Box>
            {/* CONTEXT */}
            <Stack direction="row" alignItems="stretch" sx={{ width: "100%", maxHeight: "100%", minWidth: "320px" }} spacing={1.5} >
                {/* appeals */}
                <Appeals />
                {/* appeals */}

                {/*chat*/}
                <Appeal />
                {/*chat*/}
                
                {/* info */}
                <GlobalLiveSearch />
                {/* info */}
            </Stack>
        </Box>
    )
};

export default AppAppeals;