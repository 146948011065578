import {
    Box,
    Typography,
    useTheme,
    Stack
} from "@mui/material";
import { tokens } from "../../theme";
import {Link} from 'react-router-dom';
import ChatComponent from "./Chat";
import NoChat from "./NoAppeal";



const Appeal = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            sx={{
                minWidth: "650px"
            }}
            backgroundColor={colors.primary[400]}
        >
            <ChatComponent />
            
        </Box>
    )
};

export default Appeal;