import {
    Box,
    IconButton,
    useTheme,
    Stack, InputBase
} from "@mui/material";
import {observer} from "mobx-react-lite";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { tokens } from "../../theme";
import { useState, useEffect } from "react";
import AppealListElement from "./AppealListElement";
import SearchIcon from "@mui/icons-material/Search";
import appealsStore from '../../store/appealsStore';
import AppealService from "../../services/AppealService";
import ProviderService from "../../services/ProviderService";

const Appeals = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [providers, setProviders] = useState([]);
    const [appealGroups, setAppealGroups] = useState([])

    // const [appealsCount, setAppealsCount] = useState(0)
    const [status, setStatus] = useState(1);

    useEffect(() => {
        const subscribe = async () => {
            const providers = await ProviderService.getProviders();
            setProviders(providers.data.providers)
            const appealGroups = await AppealService.getAppealGroup();
            setAppealGroups(appealGroups.data.appeal_groups)
        }
        subscribe()
    }, [  ])

    const handleAppealClick = (appeal_id) => {
        appealsStore.fetchAppeal(appeal_id)
    };

    const handleChangeProvider = (event, newValue) => {

        appealsStore.setFilterModel({
            provider: newValue,
            group: appealsStore.filterModel.group,
            open: appealsStore.filterModel.open,
            close: appealsStore.filterModel.close,
            quickFilterValues: '',
        })
    };

    const handleChangeGroup = (event, newValue) => {

        appealsStore.setFilterModel({
            provider: appealsStore.filterModel.provider,
            group: newValue,
            open: appealsStore.filterModel.open,
            close: appealsStore.filterModel.close,
            quickFilterValues: '',
        })
    };

    const handleChangeStatus = (event, newValue) => {
        setStatus(newValue)
        switch(newValue) {
            case 0:
                appealsStore.setFilterModel({
                    provider: appealsStore.filterModel.provider,
                    group: appealsStore.filterModel.group,
                    open: 1,
                    close: 1,
                    quickFilterValues: '',})
                break
            case 1:
                appealsStore.setFilterModel({
                    provider: appealsStore.filterModel.provider,
                    group: appealsStore.filterModel.group,
                    open: 1,
                    close: 0,
                    quickFilterValues: '',
                })
                break
            case 2:
                appealsStore.setFilterModel({
                    provider: appealsStore.filterModel.provider,
                    group: appealsStore.filterModel.group,
                    open: 0,
                    close: 1,
                    quickFilterValues: '',
                })
                break
            default:
                break
        }
    };

    return (
        <Box
            sx={{
                position: "relative",
                // height: "100%",
                minWidth: "320px",
                width: true ? 320 : "100vw",
                backgroundColor:
                    theme.palette.mode === "light"
                        ? "#F8FAFF"
                        : theme.palette.background,
                boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            }}
        >

            <Stack spacing={2} sx={{ maxHeight: "100%"}}>
                <Stack sx={{ width: "100%" }}>
                    <Box
                        display="flex"
                        backgroundColor={colors.primary[400]}
                        borderRadius="3px"
                        m={1}
                    >
                        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
                        <IconButton type="button" sx={{ p: 1 }}>
                            <SearchIcon />
                        </IconButton>
                    </Box>
                    {providers.length > 1
                        ? <Box>
                            <Tabs
                                value={appealsStore.filterModel.provider}
                                onChange={handleChangeProvider}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="disabled tabs example"
                            >
                                <Tab value={0} label="All" />
                                {providers.map((el) => {
                                    return <Tab value={ el.id } label={ el.name } />;
                                })}
                            </Tabs>
                        </Box>
                        : <></>
                    }
                    {appealGroups.length > 1
                        ? <Box>
                            <Tabs
                                value={appealsStore.filterModel.group}
                                onChange={handleChangeGroup}
                                // variant="scrollable"
                                // scrollButtons="auto"
                                aria-label="disabled tabs example"
                            >
                                <Tab value={0} label="All" />
                                {appealGroups.map((el) => {
                                    return <Tab value={ el.id } label={ el.name } />;
                                })}
                            </Tabs>
                        </Box>            
                        : <></>
                    }       
                    <Box>
                        <Tabs
                            value={status}
                            onChange={handleChangeStatus}
                            // variant="scrollable"
                            // scrollButtons="auto"
                            aria-label="disabled tabs example"
                        >
                            <Tab label="All" />
                            <Tab label="Open" />
                            <Tab label="Close" />
                        </Tabs>
                    </Box>
                </Stack>
                <Stack sx={{ height: "70vh", flexGrow: 1, overflowY: "scroll" }}>
                    <Stack spacing={1}>
                        {appealsStore.appeals.map((el) => {
                            return <AppealListElement {...el} handleAppealClick={handleAppealClick} isSelected={appealsStore.appeal.id === el.id} key={el.id}/>;
                        })}
                        </Stack>
                </Stack>
            </Stack>
        </Box>
    )
};

export default observer(Appeals);